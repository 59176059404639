import * as React from 'react';
import {
    ArrowDownOutlined,
} from "@ant-design/icons";
import {
    Button,
    Col,
    Row,
    Typography,
} from "antd";
import {
    PostCard,
} from "../card/PostCard";
import {
    IPostCardProps
} from '../card/PostCard';
import {
    Link
} from 'gatsby';

const {
    Title
} = Typography;

interface IPostsSectionProps {
    title: string;
    posts: IPostCardProps[]
    hasMoreButton: boolean;
}

export const PostsSection: React.FC<IPostsSectionProps> = (x) => {
    return (
        <div>
            <Title level={4} style={{
                marginBottom: 16,
            }}>{x.title} <ArrowDownOutlined/></Title>
            <Row gutter={[
                {
                    xs: 4,
                    sm: 8,
                    md: 12,
                    lg: 16,
                },
                32,
            ]}>
                {
                    x.posts.map(
                        (x, index) => (
                            <Col xs={24} sm={12} md={8} key={index}>
                                <Link to={x.url}>
                                    <PostCard
                                        thumbnail={x.thumbnail}
                                        title={x.title}
                                        url={x.url}
                                        tags={x.tags}
                                        createdAt={x.createdAt}
                                        updatedAt={x.updatedAt}/>
                                </Link>
                            </Col>
                        )
                    )
                }
            </Row>
            {
                x.hasMoreButton &&<Row justify="center" style={{
                    marginTop: 60,
                }}>
                    <Col xs={16} md={12}>
                        <Button
                            block
                            shape="round"
                            size="large"
                        >
                            더보기
                        </Button>
                    </Col>
                </Row>
            }
        </div>
    )
};
