import * as React from 'react';
import {
    Card,
    Col,
    Row,
    Typography,
    Tag,
    Space,
} from "antd";
import {
    CloudUploadOutlined,
    EditOutlined,
} from '@ant-design/icons';
import Img, {FluidObject} from 'gatsby-image';
import {
    graphql,
    useStaticQuery,
} from "gatsby";

const {Paragraph, Text} = Typography;

export interface IPostCardProps {
    title: string;
    tags: string[];
    url: string;
    thumbnail: FluidObject;
    createdAt: string;
    updatedAt: string;
}

export const PostCard: React.FC<IPostCardProps> = (x) => {

    return (
        <Card
            size="small"
            hoverable
            cover={<Img fluid={x.thumbnail} alt="thumb"/>}>
            <Paragraph
                ellipsis={{
                    rows: 2,
                }}
                strong
                style={{
                    fontSize: 16,
                    marginBottom: 8,
                }}
            >
                {x.title}
            </Paragraph>
            <Space
                wrap
                size={1}
                style={{
                    marginBottom: 16,
                }}>
                {
                    x.tags.map(
                        (y) => <Tag key={y} color="volcano" onClick={() => {
                        }}>
                            {y}
                        </Tag>
                    )
                }
            </Space>
            <Row justify="space-between">
                <Col>
                    <Text className="grey-text">
                        <EditOutlined/> {x.updatedAt}
                    </Text>
                </Col>
                <Col>
                    <Text className="grey-text">
                        <CloudUploadOutlined/> {x.createdAt}
                    </Text>
                </Col>
            </Row>
        </Card>
    )
};
