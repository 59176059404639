import * as React from "react"
import {
    graphql,
    useStaticQuery,
} from "gatsby";
import Img from 'gatsby-image';
import {
    Typography,
    Space,
    Button,
    Card,
    Row,
    Col
} from 'antd';
import {
    useLocation
} from '@reach/router';
import {
    YoutubeOutlined,
    MediumOutlined,
    LinkedinOutlined,
    FacebookOutlined,
    SlackOutlined,
    MessageOutlined,
    ArrowDownOutlined,
} from '@ant-design/icons';

import {PostsSection} from "../components/section/PostsSection";
import {DefaultLayout} from "../components/layout/DefaultLayout";

const {
    Title,
    Text,
    Paragraph,
} = Typography;

const intro = `
코드팩토리 개발 블로그에 오신것을 환영합니다.
코드팩토리는 Youtube 및 블로그로 트렌디하고 유용한 개발 팁을 강의해주는
브랜드입니다. DevOps, Backend, Frontend, Database 등
여러 강의를 기획및 제작중에 있으니 많은 관심 부탁드립니다!
`;

const snsUrls = {
    youtube: 'https://www.youtube.com/channel/UCxZ2AlaT0hOmxzZVbF_j_Sw',
    medium: 'https://medium.com/withj-kr',
    linkedIn: 'https://www.linkedin.com/in/serendipity1004/',
    facebook: 'https://www.facebook.com/codefactorykorea',
    slack: 'https://forms.gle/15Dew6W3fRQAGn7K7',
    kakao: 'https://open.kakao.com/o/gg2S2GBc'
}

// markup
const IndexPage = ({data}) => {
    const {pathname, origin} = useLocation();
    const {
        site:{
            siteMetadata,
        },
        file:{
            publicURL
        },
        allMarkdownRemark: {
            edges,
        }
    } = data;

    const markdowns = edges.map(
        (x) => {
            const {node} = x;
            const {frontmatter, fields} = node;

            return {
                title: frontmatter.title,
                tags: frontmatter.tags,
                url: fields.slug,
                thumbnail: frontmatter.thumbnail.childImageSharp.fluid,
                createdAt: frontmatter.createdAt,
                updatedAt: frontmatter.updatedAt,
            }
        }
    )

    return (
        <DefaultLayout seo={{
            title: '홈',
            description: '코드팩토리 홈페이지',
            pathname: pathname,
            image: siteMetadata.siteUrl + publicURL,
            meta: [],
        }}>
            <section className="title-section container">
                <Title>Code Factory Dev Blog</Title>
                <Text style={{
                    fontSize: 20,
                }}>
                    {intro}
                </Text>
                <Space className="social-buttons" wrap>
                    <Button type="primary"
                            icon={<YoutubeOutlined/>}
                            onClick={() => window.open(snsUrls['youtube'])}
                    >
                        YouTube
                    </Button>
                    <Button type="primary"
                            icon={<MediumOutlined/>}
                            onClick={() => window.open(snsUrls['medium'])}
                    >
                        Medium
                    </Button>
                    <Button type="primary"
                            icon={<LinkedinOutlined/>}
                            onClick={() => window.open(snsUrls['linkedIn'])}
                    >
                        LinkedIn
                    </Button>
                    <Button type="primary"
                            icon={<FacebookOutlined/>}
                            onClick={() => window.open(snsUrls['facebook'])}
                    >
                        Facebook
                    </Button>
                    <Button type="primary"
                            icon={<SlackOutlined/>}
                            onClick={() => window.open(snsUrls['slack'])}
                    >
                        Slack
                    </Button>
                    <Button type="primary"
                            icon={<MessageOutlined/>}
                            onClick={() => window.open(snsUrls['kakao'])}
                    >
                        Kakao
                    </Button>
                </Space>
            </section>
            <section className="container">
                <Space direction="vertical" size={120} wrap={false}>
                    <PostsSection title="최신 글" posts={markdowns} hasMoreButton={false}/>
                </Space>
            </section>
        </DefaultLayout>
    )
}

export const pageQuery = graphql`
    query AllThumbsQuery {
        site {
            siteMetadata {
                siteUrl
            }
        }
        file(name:{eq: "logo-social"}){
            publicURL
        }
        allMarkdownRemark (
            filter: {
                fields: {
                    collection: {
                        eq: "blog"
                    }
                },
                frontmatter: {
                    published: {
                        eq: true
                    }
                }
            }, 
            sort: {
                fields: [frontmatter___createdAt, frontmatter___title] 
                order: [DESC, DESC]
            }){
            edges {
                node {
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        tags
                        createdAt
                        updatedAt
                        featuredImage {
                            childImageSharp {
                                fluid{
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        thumbnail {
                            childImageSharp{
                                fluid {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export default IndexPage;
